import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { CBXContext } from '../@codbrix/components/provider'
import BlockEditor from '../@codbrix/components/form/fields/BlockEditor'

export default function Home() {

    const {project, isLoggedIn} = useContext(CBXContext)
    const navigate = useNavigate()

    useEffect(() => {
        if(isLoggedIn){
            navigate(project?.homepage)
        }
        else{
            navigate(project?.entry || '404')
        }
        
    }, [isLoggedIn])

    return (
        <div></div>
    )
}
