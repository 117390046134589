import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Layout } from "./layout/Layout";
import { theme } from "./theme";
import { Codbrix } from "./@codbrix/components/provider";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import store from './@codbrix/store'
import { Provider } from 'react-redux'

import './styles.css'
import './css/codeflask.css'
import 'react-quill/dist/quill.bubble.css';
import PublicRoutes from "./views/PublicRoutes";
import RenderPage from "./views/RenderPage";
import Operations from "./views/Operations";
// import Test from "./views/Test";


var base_url:any = localStorage.getItem('cbx_base_url');

base_url = '/'

// if(!base_url){
//     localStorage.removeItem('cbx_token')
//     base_url = prompt("Enter URL to continue")
//     // if(`${base_url}`.startsWith('https://')){
//     //   base_url = 'https://' + localStorage.setItem('cbx_base_url', base_url)
//     // }
//     localStorage.setItem('cbx_base_url', base_url)
// }

export const App = () => {

  React.useEffect(() => {
    console.log("Welcome to Bepoz Oolio CMS")
  }, [])

  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Router>
            <Routes>
                <Route path="/" element={<Codbrix base_url={base_url} />}>
                  <Route path='/' element={<Home/>}/>
                  <Route path='/cms' element={<Home/>}/>
                  <Route element={<Layout />}>
                    <Route path="/cms/:page" element={<RenderPage/>} />
                    <Route path="/cms/:page/:id" element={<Operations/>} />
                    <Route path="/404" element={<>404 Not Found</>} />
                  </Route>
                  <Route path="/public/:page/:route" element={<PublicRoutes/>} />
                  {/* <Route path="/test" element={<Test/>} /> */}
                </Route>
            </Routes>
        </Router>
      </ChakraProvider>
    </Provider>
  )
};
